import { gql } from '@apollo/client'

export const CREATE_CONTRACT = gql`
    mutation CreateContract(
                $sportsmanId: String!
                $iin: String!
                $fullName: String!
                $parentFullName: String
                $mainPhone: String!
              ){
              createContract(
                sportsmanId: $sportsmanId
                iin: $iin
                fullName: $fullName
                parentFullName: $parentFullName
                mainPhone: $mainPhone
              )
  }
`

