//core
import { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

//hooks
import { useUpdateSportsman } from './hooks/mutation/useUpdateSportsman'
import { useCreateContract } from './hooks/mutation/useCreateContract'
import { useSportsmanDataLink } from './hooks/query/useSportsmanDataLink'

//utils
import { ImageCropper } from './utils/ImageCropper/ImageCropper'
import { REG_IIN, REG_PHONE } from './utils/regulars'

//cache
import { isNotifedVar } from './utils/cache'

//components
import { NotFound } from './NotFound/NotFound'
import { Loader } from './Loader/Loader'

//styles
import styles from './App.module.css'

//images
import logo from './assets/images/white_logo.png'

export const App = () => {

  //global hooks
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  //params
  const id = searchParams.get('id')
  const type = searchParams.get('type')

  //states
  const [inputsData, setInputsData] = useState({
    iin: '',
    fullName: '',
    birthDate: new Date().toISOString().split('T')[0],
    parentFullName: '',
    mainPhone: '',
    addPhone: '',
    contract: false,
    medical: false,
  })

  const [inputImg, setInputImg] = useState('')
  const [cropImage, setCropImage] = useState({})
  const [fileDoc, setFileDoc] = useState({})
  const [isFileProcess, setIsFileProcess] = useState(false)
  const [isSendingProcess, setIsSendingProcess] = useState(false)
  const [contractReady, setContractReady] = useState(false)
  const [contractLink, setContractLink] = useState('')
  const [modalChatLink, setModalChatLink] = useState(false)

  //hooks
  const { updateSportsman } = useUpdateSportsman(setModalChatLink, type)
  const { createContract } = useCreateContract(setContractReady, setContractLink)
  const { linkLoading, linkData } = useSportsmanDataLink(id)

  //handles
  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target
    let list = { ...inputsData }
    list[name] = type === 'checkbox' ? checked : value
    if (type !== 'checkbox') {
      setIsFileProcess(false)
      setContractReady(false)
      list['contract'] = false
    }
    setInputsData(list)
  }

  const handleFileInputChange = (e) => {
    const file = e.target.files[0]
    const name = e.target.name
    setFileDoc(file)
    name === 'medical' &&
      setInputsData({
        ...inputsData,
        medical: true
      })
  }

  const handlePhotoInputChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      setInputImg(reader.result)
    }, false)
    reader.readAsDataURL(file)
  }

  const handleCreateContract = () => {
    if (!isFileProcess) {
      if (!inputsData.iin || inputsData.iin.match(REG_IIN) === null) {
        isNotifedVar('Проверьте ИИН')
      } else if (!inputsData.fullName || inputsData.fullName.length > 100) {
        isNotifedVar('Проверьте имя спортсмена')
      } else if (!inputsData.mainPhone || inputsData.mainPhone.match(REG_PHONE) === null) {
        isNotifedVar('Проверьте основной телефон')
      } else if (!inputsData.addPhone || inputsData.addPhone.match(REG_PHONE) === null) {
        isNotifedVar('Проверьте дополнительный телефон')
      } else {
        setIsFileProcess(true);
        createContract(inputsData, id)
      }
    } else {
      isNotifedVar('Идет формирование договора!')
    }
  }

  const handleUpdateSportsman = () => {
    if (!isSendingProcess) {
      if (!inputsData.contract) {
        isNotifedVar('Ознакомьтесь с договором и техникой безопасности')
      } else if (JSON.stringify(fileDoc.file) === '{}') {
        isNotifedVar('Добавьте справку')
      } else if (JSON.stringify(cropImage) === '{}') {
        isNotifedVar('Добавьте фото спортсмена')
      } else {
        setIsSendingProcess(true)
        updateSportsman(inputsData, { file: fileDoc, sportsmanId: id }, { file: cropImage, sportsmanId: id })
      }
    } else {
      isNotifedVar('Идет отправка данных!')
    }
  }

  const handleChatLink = () => {
    if (type === 'pro') {
      window.location.href = 'https://chat.whatsapp.com/Fy5hNVyYok47aQ7rnUAm14'
    }
    if (type === 'base') {
      window.location.href = 'https://chat.whatsapp.com/HNG1Z9ZkShf7ssULr2hsCR'
    }
    if (type === 'weekend') {
      window.location.href = 'https://chat.whatsapp.com/BqLyEUtaXZB4D1NBo7O6k6'
    }
    if (type === 'schoolpalace') {
      window.location.href = 'https://chat.whatsapp.com/Lp7szADNIwXGPRfQuw2HIv'
    }
    if (type === 'schoolpalaceweekend') {
      window.location.href = 'https://chat.whatsapp.com/KClsvph8ZFC6oPZ4udMalI'
    }
    if (type === 'mega') {
      window.location.href = 'https://chat.whatsapp.com/CxQ7L4YLBub9Jbq4GJw2so'
    }
    if (type === 'megaweekend') {
      window.location.href = 'https://chat.whatsapp.com/L3Mv9NiFK83FNntQnWem8d'
    }
  }

  const handleBack = () => {
    navigate('/')
  }

  if (linkLoading) return <Loader />
  if (!id || linkData === 'not' || linkData === null) return <NotFound />

  return (
    <div className={styles.container}>


      {modalChatLink ?
        <div className={styles.modalContainer}>
          <button
            className={styles.button}
            onClick={handleChatLink}
          >
            Вступить в WhatsApp чат
          </button>
          <p className={styles.links}></p>
          <p className={styles.label}>
            Группа - {type === 'mega' ? 'MEGA BASE' : type === 'schoolpalace' ? 'Дворец школьников' : type === 'schoolpalaceweekend' ? 'Дворец школьников WEEKEND' : type === 'megaweekend' ? 'MEGA WEEKEND' : type.toUpperCase()}
          </p>
          <p className={styles.label_desc}>* в группе всегда актуальная информация по тренировкам, отменам или переносам, а так же данные для кадого родителя и спортсмена</p>
          <p
            className={styles.close}
            onClick={handleBack}
          >
            х
          </p>
        </div>
        : null
      }

      <img src={logo} className={styles.logo} alt="" />

      <div className={styles.block}>
        <p className={styles.label}>ИИН*</p>
        <input
          className={styles.input}
          placeholder='введите ИИН'
          type='text'
          name='iin'
          value={inputsData.iin}
          onChange={handleInputChange}
        />
        <p className={styles.label_desc}>* если спортсмену менее 18 лет, заполняется ИИН родителя</p>
      </div>

      <div className={styles.block}>
        <p className={styles.label}>ФИО спортсмена</p>
        <input
          className={styles.input}
          placeholder='введите имя и фамилию'
          type='text'
          name='fullName'
          value={inputsData.fullName}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.block}>
        <p className={styles.label}>Дата рождения спортсмена</p>
        <input
          className={styles.input}
          placeholder='введите имя и фамилию'
          type='date'
          name='birthDate'
          value={inputsData.birthDate}
          onChange={handleInputChange}
        />
      </div>

      <div className={styles.block}>
        <p className={styles.label}>ФИО родителя*</p>
        <input
          className={styles.input}
          placeholder='введите имя и фамилию'
          type='text'
          name='parentFullName'
          value={inputsData.parentFullName}
          onChange={handleInputChange}
        />
        <p className={styles.label_desc}>* заполняется только если спортсмену менее 18 лет</p>
      </div>

      <div className={styles.block}>
        <p className={styles.label}>Основной номер телефона</p>
        <div className={styles.labels_box}>
          <span className={styles.label_inside}>+7</span>
          <input
            className={styles.input}
            placeholder='введите номер'
            type='text'
            name='mainPhone'
            value={inputsData.mainPhone}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.label}>Дополнительный номер телефона</p>
        <div className={styles.labels_box}>
          <span className={styles.label_inside}>+7</span>
          <input
            className={styles.input}
            placeholder='введите номер'
            type='text'
            name='addPhone'
            value={inputsData.addPhone}
            onChange={handleInputChange}
          />
        </div>
      </div>

      <div className={styles.block}>
        <p className={styles.label}>Договор и техника безопасности</p>

        {contractReady ?
          <>
            <div className={styles.block_group}>
              <input
                className={styles.input_checkbox}
                type='checkbox'
                name='contract'
                checked={inputsData.contract}
                onChange={handleInputChange}
              />
              <p className={styles.label_accept}>Я ознакомлен(на) и согласен(на) с</p>
            </div>
            <p className={styles.label_accept}>
              <a
                href={`data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${contractLink}`}
                download='contract.docx'
              >
                <span className={styles.links}>условиями договора и техникой безопасности</span>
              </a>
            </p>
          </>
          :
          <button
            className={styles.buttonAccept}
            onClick={handleCreateContract}
          >
            {isFileProcess ? '...ждите' : 'Сформировать договор и технику безопасности'}
          </button>
        }
      </div>

      <div className={styles.block}>
        <p className={styles.label}>Медицинская справка № 027/у (PDF)</p>
        <input
          className={styles.input_file}
          type='file'
          name='medical'
          onChange={handleFileInputChange}
          accept="application/pdf"
        />
      </div>

      <div className={styles.block}>
        <p className={styles.label}>Фото спортсмена</p>
        <input
          className={styles.input_file}
          type='file'
          name='photo'
          onChange={handlePhotoInputChange}
          accept='image/*'
        />
      </div>

      <button
        className={styles.button}
        onClick={handleUpdateSportsman}
      >
        {isSendingProcess ? '...отправка' : 'Сохранить и отправить'}
      </button>

      {inputImg &&
        <ImageCropper
          inputImg={inputImg}
          setInputImg={setInputImg}
          setCropImage={setCropImage}
        />
      }

    </div>
  )
}
