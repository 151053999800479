import { gql } from '@apollo/client'

export const UPLOAD_PHOTO = gql`
    mutation UploadPhoto(
                $file: Upload!
                $sportsmanId: String!
              ){
              uploadPhoto(
                file: $file
                sportsmanId: $sportsmanId
              )
  }
`

