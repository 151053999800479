//core
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

//components
import { Pages } from './Pages'

//styles
import './index.css'

const uploadLink = new createUploadLink({
  uri: 'https://icerudi.kz/api/gql/'
  // uri: 'http://192.168.1.197:4000/graphql'
})

const authLink = setContext((operation, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiZ3Vlc3QiLCJpYXQiOjE3MDkxODk2Mjl9.2FseOWnC06Xs61XUhfdTHJrmy4PmJu5zgS70jr0ldw8`,
      'x-apollo-operation-name': operation.operationName,
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache(),
  useWebSocket: false
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter >
    <ApolloProvider client={client}>
      <Pages />
    </ApolloProvider>
  </BrowserRouter>
)