//styles
import styles from './Main.module.css'

//images
import logo from './assets/images/white_logo.png'
import insta from './assets/images/insta.png'

export const Main = () => {

  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt='' />
      <p>Школа фигурного катания г. Астана</p>
      <a href='https://www.instagram.com/rudi_iceclub/' target='_blank' rel="noreferrer">
        <img src={insta} className={styles.insta} alt='Инстаграм школы фигурного катания RUDI в Астане' />
      </a>
    </div>
  )
}


