//core
import { useQuery } from '@apollo/client'

//gql
import { SPORTSMAN_DATA_LINK } from '../../gql/query/SportsmanDataLink'

export const useSportsmanDataLink = (sportsmanId) => {

    //queries
    const { loading, data } = useQuery(SPORTSMAN_DATA_LINK, {
        fetchPolicy: 'network-only',
        variables: {
            sportsmanId: sportsmanId
        }
    })

    return {
        linkLoading: loading,
        linkData: data?.sportsmanDataLink,
    }
}