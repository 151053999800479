//styles
import style from './NotFound.module.css'

export const NotFound = () => {
    return (

        <>
            <div className={style.wrap}>
                <span className={style.errorMessage}>Страница недействительна</span>
                <br />
                <span className={style.recommendationMessage}>Скорее всего ссылка уже просрочена</span>
            </div>

        </>

    )
}

