//core
import { useMutation } from '@apollo/client'

//gql
import { UPLOAD_FILE } from "../../gql/mutation/UploadFile"

export const useUploadFile = () => {

    const [upload] = useMutation(UPLOAD_FILE)

    const uploadFile = async (file, sportsmanId) => {
        upload({
            variables: {
                file,
                sportsmanId: sportsmanId,
            }
        })
    }

    return {
        uploadFile
    }
}