//core
import { gql } from '@apollo/client'

export const SPORTSMAN_DATA_LINK = gql`
    query SportsmanDataLink(
            $sportsmanId: ID!
        ){
            sportsmanDataLink(
                sportsmanId: $sportsmanId
                )
        }
    `