//styles
import style from './Loader.module.css'

export const Loader = () => {
    return (

        <>
            <div className={style.wrap}>
                <span className={style.errorMessage}>...проверка данных</span>
                <div className={style.loader} />
            </div>

        </>

    )
}

