import Resizer from 'react-image-file-resizer'

// Изменение размера фото статьи
export const resizeFile = (image, size) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            image,
            size,
            size,
            "JPEG",
            55,
            0,
            (uri) => { resolve(uri) },
            "file"
        )
    })