import { gql } from '@apollo/client'

export const UPLOAD_FILE = gql`
    mutation UploadFile(
                $file: Upload!
                $sportsmanId: String!
              ){
              uploadFile(
                file: $file
                sportsmanId: $sportsmanId
              )
  }
`

