import { gql } from '@apollo/client'

export const UPDATE_SPOTSMAN = gql`
    mutation UpdateSportsman(
                $sportsmanId: String!
                $iin: String!
                $fullName: String!
                $birthDate: String!
                $parentFullName: String
                $mainPhone: String!
                $addPhone: String
                $contract: Boolean!
                $medical: Boolean!
              ){
              updateSportsman(
                sportsmanId: $sportsmanId
                iin: $iin
                fullName: $fullName
                birthDate: $birthDate
                parentFullName: $parentFullName
                mainPhone: $mainPhone
                addPhone: $addPhone
                contract: $contract
                medical: $medical
              )
  }
`

