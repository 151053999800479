//core
import { useMutation } from '@apollo/client'

//gql
import { UPLOAD_PHOTO } from "../../gql/mutation/UploadPhoto"

//utils
import { resizeFile } from '../../utils/ImageResizer/resizeFile'


export const useUploadPhoto = () => {

    const [upload] = useMutation(UPLOAD_PHOTO)

    const uploadPhoto = async (cropImage, sportsmanId) => {
        const file = await resizeFile(cropImage.file)
        upload({
            variables: {
                file,
                sportsmanId: sportsmanId,
            }
        })
    }

    return {
        uploadPhoto
    }
}