//core
import { useReactiveVar } from '@apollo/client'

//utils
import { isNotifedVar } from '../utils/cache'

//styles
import style from './Notify.module.css'

export const Notify = () => {

    const isNotifed = useReactiveVar(isNotifedVar)

    if (!isNotifed) {
        return null
    }

    return (
        <>
            <div className={style.wrap}>
                <span>{isNotifedVar()}</span>
            </div>
        </>
    )
}
