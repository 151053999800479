//core
import { useState } from 'react'
import Cropper from 'react-easy-crop'

//utils
import { getCroppedImg } from './cropImage'

//styles
import style from './ImageCropper.module.css'

export const ImageCropper = ({ inputImg, setCropImage, setInputImg }) => {

    const [blob, setBlob] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)

    const onCropComplete = async (_, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            inputImg,
            croppedAreaPixels,
            1
        )
        setBlob(croppedImage)
    }

    const handleSubmitImage = () => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            setInputImg(null)
        }, false)
        if (blob) {
            reader.readAsDataURL(blob)
            const file = new File([blob], "name.jpg")
            setCropImage({ file: file })
        }
    }

    const handleCancelImage = () => {
        setInputImg(null)
    }

    return (
        <>

            <Cropper
                style={{ containerStyle: { position: 'fixed', backgroundColor: 'white', zIndex: 199 } }}
                image={inputImg}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
            />

            <div className={style.cropButtons}>
                <div className={style.submitCrop} onClick={handleSubmitImage}>Готово</div>
                <div className={style.cancelCrop} onClick={handleCancelImage}>Отмена</div>
            </div>


        </>
    )
}