//core
import { useMutation } from '@apollo/client'

//gql
import { CREATE_CONTRACT } from '../../gql/mutation/CreateContract'

export const useCreateContract = (setContractReady, setContractLink) => {

    const [creating] = useMutation(CREATE_CONTRACT, {
        onCompleted: (data) => {
            setContractReady(true)
            setContractLink(data.createContract)
        }
    })

    //hooks
    const createContract = (inputsData, sportsmanId) => {
        creating({
            variables: {
                sportsmanId: sportsmanId,
                iin: inputsData.iin.trim(),
                fullName: inputsData.fullName.trim(),
                parentFullName: inputsData.parentFullName.trim(),
                mainPhone: `+7${inputsData.mainPhone.trim()}`,
            }
        })
    }

    return {
        createContract
    }
}