//core
import { Route, Routes } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'

//cache
import { isNotifedVar } from './utils/cache'

//components
import { App } from './App'
import { Main } from './Main'
import { NotFound } from './NotFound/NotFound'
import { Notify } from './Notify/Notify'

export const Pages = () => {

  const isNotifed = useReactiveVar(isNotifedVar)

  isNotifed !== '' && setTimeout(isNotifedVar, 2500, '')

  return (
    <>

      <Routes>
        <Route path='/' element={<Main />} />
        <Route path='/sportsman' element={<App />} />
        <Route path='/*' element={<NotFound />} />
      </Routes>

      <Notify />

    </>
  )
}


