//core
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'

//hooks
import { useUploadFile } from './useUploadFile'
import { useUploadPhoto } from './useUploadPhoto'

//gql
import { UPDATE_SPOTSMAN } from '../../gql/mutation/UpdateSportsman'

//cache
import { isNotifedVar } from '../../utils/cache'

export const useUpdateSportsman = (setModalChatLink, type) => {

    //global hooks
    const navigate = useNavigate()

    const [updating] = useMutation(UPDATE_SPOTSMAN, {
        onCompleted: () => {
            isNotifedVar('Данные успешно сохранены')
            if (type === 'unlimited') {
                setTimeout(() => navigate('/'), 2000)
            } else {
                setTimeout(() => setModalChatLink(true), 2000)
            }
        }
    })

    //hooks
    const { uploadFile } = useUploadFile()
    const { uploadPhoto } = useUploadPhoto()

    const updateSportsman = (inputsData, document, photo) => {
        uploadFile(document.file, document.sportsmanId)
        uploadPhoto(photo.file, photo.sportsmanId)
        updating({
            variables: {
                sportsmanId: document.sportsmanId || photo.sportsmanId,
                iin: inputsData.iin.trim(),
                fullName: inputsData.fullName.trim(),
                birthDate: inputsData.birthDate,
                parentFullName: inputsData.parentFullName.trim(),
                mainPhone: `+7${inputsData.mainPhone.trim()}`,
                addPhone: inputsData.addPhone ? `+7${inputsData.addPhone.trim()}` : '',
                contract: inputsData.contract,
                medical: inputsData.medical,
            }
        })
    }

    return {
        updateSportsman
    }
}